<template>
    <div class="candidate-details-tab__body">

        <div class="chat__box">
            <div class="chat__box__body">
                <div class="chat__box__head">
                    <div class="thumb">
                        <router-link :to="{name: 'applicant.details', params: {id: candidate.id}}">
                            <message-user-picture :src="candidate.user.profile_image" :alt="candidate.user.name"></message-user-picture>
                        </router-link>
                    </div>

                    <div class="title">
                        <router-link :to="{name: 'applicant.details', params: {id: candidate.id}}">
                            <p class="name">{{  candidate.user.name }} </p>
                        </router-link>
                        <div class="info">
                            <p class="job__title">
                                {{  candidate.job_title }}
                                <span class="state" v-if="candidatePipeline.name">{{ candidatePipeline.name }}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="chat__box__content__area">
                    <div class="chat__box__content" id="containerBox">

                        <infinite-loading spinner="waveDots" direction="top" @infinite="getApplicantMessages">
                            <div slot="no-more"></div>
                            <div slot="no-results"></div>
                        </infinite-loading>

                        <div v-if="firstMessageDate && showFirstMessage" role="alert" class="alert fade show alert-warning text-center my-1 p-2 conversation__first__message">
                            {{ $t('Conversation started on') }} {{ firstMessageDate.slice(0, 12) }}.
                        </div>

                        <div class="conversation" v-for="(message, index) in messages" v-bind:key="index" :class="message.id === lastMessageId ? 'active' : ''">
                            <div class="conversation__thumb">
                                <message-user-picture :src="message.image" :alt="message.image"></message-user-picture>
                            </div>
                            <div class="conversation__text__group">
                                <div class="conversation__header">
                                    <div class="title">
                                        <p class="name">
                                            <span :title="message.name" >{{ shortConversationOwnerName(message.name, 25) }}</span>
                                            <span v-if="message.role" class="role">{{ message.role }}</span>
                                        </p>
                                    </div>
                                    <span class="time">{{ message.created_at }}</span>
                                </div>
                                <div class="text ej-ck-editor" style="white-space: pre-line" v-if="message.message"
                                   v-html="transformUrlToLink(message.message)"></div>
                                <div class="attachment__wrap" v-if="message.attachment">
                                    <div class="attachments">
                                        <a :href="message.attachment" target="_blank" class="attached__item">
                                            <div class="icon">
                                                <i :class="message.media_icon"></i>
                                            </div>
                                            <div class="content">
                                                <p class="name">{{ message.media_name }}</p>
                                                <span class="size">{{ message.media_size }}</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="message__box">
                        <div class="editor__wrapper ej-ck-editor" id="conversation-editor">
                            <ckeditor @input="draftMessages" ref="conversation_message" :editor="editor" :config="conversationEditorConfig" v-model.trim="form.message" @ready="onConversationReady"></ckeditor>
                        </div>

                        <div class="message__bottom">
                            <div class="attachment__wrap" v-if="filename">
                                <p class="attachment">
                                    {{ reduceLargeString }}
                                    <a href="javascript:void(0)" @click="removeFile"><i class="eicon e-delete"></i></a>
                                </p>
                            </div>
                            <div class="button__group">
                                <label class="attachment__input">
                                    <input type="file" ref="file" name="attach" :key="fileKey" @change="attachFile">
                                    <span class="social-button semi-button-primary"><i class="eicon e-attachment"></i></span>
                                </label>
                                <conversation-submit-button :click="sendMessage" :loading="isLoading">{{ $t("Send") }}</conversation-submit-button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import client from "../../../app/api/Client";
import {canOrganizeCandidate} from "../../../config/permission";
import MessageUserPicture from "../../conversations/MessageUserPicture";
import ConversationSubmitButton from "../../buttons/ConversationSubmitButton";
import { find, first, debounce } from "lodash";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {ckEditorConversationOptions} from "../../../config/options";
import {apiCallScheduler, apiCallTimer} from "../../../extra/messageConversationHelper";
import Vue from "vue";
import InfiniteLoading from "vue-infinite-loading";
import { LOAD_MESSAGE_NOTIFICATION } from "../../../constants/action-type";
import {mapActions} from "vuex";

export default {
    props: ['candidate'],
    data() {
        return {
            errors: {
                message: '',
                file: ''
            },
            form: {
                message: '',
                file: '',
            },
            isLoading: false,
            filename: '',
            fileKey: null,
            messages: [],
            messagePage: 1,
            showFirstMessage: false,
            firstMessageDate: null,
            lastMessageId: null,
            editor: DecoupledEditor,
            ckeditorInstance: null
        }
    },

    components: {
        MessageUserPicture,
        ConversationSubmitButton,
        ckeditor: CKEditor.component,
        InfiniteLoading
    },

    computed: {
        canOrganizeCandidate() {
            return canOrganizeCandidate();
        },

        candidatePipeline() {
            return find(this.candidate.job_pipelines, p => p.id == this.candidate.pipeline_index) || first(this.candidate.job_pipelines);
        },

        conversationEditorConfig() {
            let placeholderConfig = {'placeholder': this.$t(`Type your message here`)};
            return {...ckEditorConversationOptions, ...placeholderConfig};
        },

        applicantId() {
            return this.candidate.id;
        },

        reduceLargeString() {
            let val = this.filename;
            if (val.length > 10) {
                return '...' + val.substring(val.length - 10, val.length);
            }
            return val;
        },
    },

    methods: {

        ...mapActions([LOAD_MESSAGE_NOTIFICATION]),

        async getApplicantMessages($state) {

            try {
                let queryParams = {params: {page: this.messagePage}};

                let {data: {data: {applicant}}} = await this.getCompanyApplicantMessages(queryParams);

                this.form.message = applicant.draft_message;

                if(applicant.messages.data.length > 0) {
                    this.messages.unshift(...applicant.messages.data);
                    this.firstMessageDate = applicant.messages.data[0].created_at;

                    $state.loaded();

                    if (this.messagePage < applicant.messages.last_page) {
                        this.messagePage = applicant.messages.current_page + 1;
                    } else {
                        this.showFirstMessage = true;
                        $state.complete();
                    }
                } else {
                    $state.complete();
                }

            } catch ({response}) {

            }
        },

        async getCompanyApplicantMessages(params) {
            return await client().get(`conversation/applicants/` + this.applicantId, params);
        },

        async sendMessage() {
            if (this.checkValidMessage()) {

                apiCallScheduler.clearFn();

                this.isLoading = true;

                await this.saveMessage();

                this.ckeditorInstance.focus();

                apiCallScheduler.setFn(setTimeout(async () => {
                    await this.getConversationNotification();
                }, apiCallTimer));

                this.isLoading = false;
            }
        },

        checkValidMessage() {
            return this.form.message !=='' || this.form.file !== '';
        },

        async saveMessage() {
            const formData = new FormData();
            formData.append('file', this.form.file);
            formData.append('message', this.form.message);

            try {

                let {data : {data: { applicant, lastMessage }}} = await this.saveCompanyMessage(formData);

                if (applicant.messages.data.length) {
                    this.messagePage = applicant.messages.current_page;
                    this.messages = applicant.messages.data;
                }

                this.lastMessageId = lastMessage.id;
                this.clearMessage();

                setTimeout(() => {
                    this.lastMessageId = null
                }, 2000);

            } catch ({response}) {

            } finally {
                Vue.nextTick(function () {
                    const div = document.getElementById('containerBox');
                    div.scrollTop = div.scrollHeight;
                })
            }
        },

        async saveCompanyMessage(formData) {
            return await client().post(`conversation/applicants/` + this.applicantId + `/message`, formData);
        },

        async getConversationNotification() {
            this[LOAD_MESSAGE_NOTIFICATION]();
        },

        shortConversationOwnerName(val, limit = 20) {
            if (val.length > limit) {
                return val.substring(0, limit);
            }
            return val;
        },

        clearMessage() {
            this.form.message = '';
            this.errors.message = '';
            this.form.file = '';
            this.errors.file = '';
            this.filename = '';
            const input = this.$refs.file;
            input.type = 'file';
            localStorage.removeItem(this.key);
        },

        removeFile() {
            this.form.file = '';
            this.filename = '';
        },

        attachFile() {
            this.fileKey = Math.random();
            this.errors.file = '';
            let FileSize = this.$refs.file.files[0].size / 1024 / 1024; // in MB
            if (FileSize > 2) {
                this.errors.file = this.$t(`File size should be under 2 mb!`);
                const input = this.$refs.file;
                input.type = 'text';
                input.type = 'file';
                this.$toast.error(this.$t(`File size should be under 2 mb!`));
            } else {
                this.form.file = this.$refs.file.files[0];
                this.filename = this.form.file.name;
            }

        },

        draftMessages: debounce(function () {
            client().post(`job/applicant/${this.candidate.id}/draft-message`, {message: this.form.message})
                .then(() => {

                }).catch(({response: {data}}) => {
            })
        }, 1000),

        onConversationReady(editor) {
            const toolbarContainer = document.querySelector('#conversation-editor');
            toolbarContainer.prepend(editor.ui.view.toolbar.element);
            editor.focus();
            this.ckeditorInstance = editor;
        },
    }
}
</script>

<style scoped>

.chat__box {
    border-left: 1px solid #f5f7fd;
    display: flex;
    position: relative;
    background: #fff;
    border-radius: 10px;
}

.chat__box .chat__box__head {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #f5f7fd;
}

.chat__box .chat__box__head .thumb {
    height: 40px;
    width: 40px;
    flex: 0 0 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
    cursor: pointer;
}

.chat__box .chat__box__head .thumb img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.chat__box .chat__box__head .title {
    margin-right: auto;
    padding-right: 10px;
}

.chat__box .chat__box__head .title .name {
    font-size: 16px;
    font-weight: 500;
    color: #1c2238;
    cursor: pointer;
    line-height: 1;
    margin-bottom: 3px;
}

.chat__box .chat__box__head .title .info {
    font-size: 14px;
    font-weight: 400;
    color: #7d8091;
}

.chat__box .chat__box__head .title .info .state {
    margin-left: 5px;
}

.chat__box .chat__box__head .title .info .state:before {
    content: '|';
    padding-right: 5px;
}

.chat__box .chat__box__body {
    flex: 1;
}

@media all and (max-width: 1199px) {
    .chat__box .chat__box__body {
        position: relative;
    }
}


.chat__box__content__area .active {
    background: #2fc1e112;
}

button.button.info-button.disabled {
    background: #2fc1e1 !important;
    color: #fff !important;
}

.chat__box .chat__box__body .chat__box__content__area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    flex: 1;
    height: calc(100vh - 390px);
    min-height: 359px;
    padding-bottom: 15px;
}
@media all and (max-width: 1600px) {
    .chat__box .chat__box__body .chat__box__content__area {
        height: calc(100vh - 336px);
    }
}
@media all and (max-width: 1200px) {
    .chat__box .chat__box__body .chat__box__content__area {
        height: calc(100vh - 237px);
    }
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow-y: auto
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 20px;
    -webkit-transition: background .3s ease;
    transition: background .3s ease
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation:not(:last-child) {
    border-bottom: 1px solid #f5f7fd
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__thumb {
    height: 30px;
    width: 30px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__thumb img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group {
    flex: 1;
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .conversation__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 2px
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .conversation__header .title .name {
    font-size: 14px;
    font-weight: 600;
    color: #1c2238;
    margin-bottom: 0 !important
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .conversation__header .title .name .role {
    color: #597dfc;
    font-weight: 400;
    margin-left: 5px;
    font-size: 12px
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .conversation__header .title .name .role:before {
    content: '('
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .conversation__header .title .name .role:after {
    content: ')'
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .conversation__header .time {
    font-size: 10px;
    font-weight: 400;
    color: #7d8091
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .text {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    color: #7d8091
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .text:not(:last-child) {
    margin-bottom: 12px
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .text a {
    color: #597dfc;
    word-break: break-all
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap {
    display: inline-block;
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachments {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachments .attached__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachments .attached__item .icon {
    width: 50px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #597dfc;
    color: #fff;
    font-size: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachments .attached__item .content {
    padding: 5px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: rgba(89, 125, 252, 0.8)
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachments .attached__item .content .name {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    line-height: 1;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachments .attached__item .content .size {
    font-size: 12px;
    color: #fff;
    font-weight: 400
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachment__bottom {
    padding: 5px 10px;
    border-top: 1px solid rgba(89, 125, 252, 0.35)
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachment__bottom a {
    font-size: 14px;
    font-weight: 500;
    color: #597dfc
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content .conversation .conversation__text__group .attachment__wrap .attachment__bottom a i {
    font-size: 12px;
    margin-right: 5px
}

.chat__box .chat__box__body .chat__box__content__area .message__box {
    position: relative;
    border-radius: 5px;
    margin-right: 15px;
    margin-left: 15px;
}

.chat__box .chat__box__body .chat__box__content__area .message__box textarea {
    height: 80px;
    width: 100%;
    padding: 13px;
    font-size: 14px;
    font-weight: 400;
    color: #7d8091;
    resize: none;
    background: transparent;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .button__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: 5px;
    gap: 5px;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .button__group .attachment__input input {
    display: none
}

.chat__box .chat__box__body .chat__box__content__area .message__box .button__group .attachment__input .social-button {
    cursor: pointer;
    height: 35px;
    width: 35px;
    line-height: 38px;
    color: #59c1fc;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .button__group .semi-button {
    height: auto;
    padding: 7px 22px;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .button__group .attachment__input .social-button:hover {
    background: #597dfc;
    color: #fff
}

.chat__box .chat__box__body .chat__box__content__area .message__box .message__bottom {
    position: relative;
    margin-top: -42.5px;
    margin-right: 10px;
    border: none;
    float: right;
    display: inline-flex;
    justify-content: flex-end;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .attachment__wrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .attachment__wrap .attachment {
    font-size: 12px;
    font-weight: 400;
    color: #1c2238;
    background: rgba(89, 125, 252, 0.1);
    border-radius: 20px;
    padding: 5px 33px 5px 10px;
    box-shadow: 2px 2px 5px 0 rgba(89, 125, 252, 0.1);
    position: relative;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .attachment__wrap .attachment a {
    position: absolute;
    top: 0;
    right: 0;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: rgba(255, 95, 116, 0.1);
    color: #ff5f74;
    line-height: 28px;
    text-align: center;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .attachment__wrap .attachment a:hover {
    background: #ff5f74;
    color: #fff;
}



.chat__box .chat__box__body .chat__box__content__area .chat__box__content::-webkit-scrollbar {
    width: 7px;
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(125, 128, 145, 0.3);
    background-image: linear-gradient(to right bottom, rgba(89, 125, 252, 0.1), rgba(89, 125, 252, 0.1));
}

.chat__box .chat__box__body .chat__box__content__area .chat__box__content::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #597dfc;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .ck.ck-editor__editable_inline::-webkit-scrollbar {
    width: 7px;
}

.chat__box .chat__box__body .chat__box__content__area .message__box .ck.ck-editor__editable_inline::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(125, 128, 145, 0.3);
    background-image: linear-gradient(to right bottom, rgba(89, 125, 252, 0.1), rgba(89, 125, 252, 0.1));
}

.chat__box .chat__box__body .chat__box__content__area .message__box .ck.ck-editor__editable_inline::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #597dfc;
}

.conversation__text__group .text a {
    word-break: break-all;
}

.conversation__first__message {
    margin-left: 70px;
    margin-right: 70px;
    font-size: 11px;
}

@media all and (max-width: 991px) {
    .conversation__first__message {
        margin-left: 50px;
        margin-right: 50px;
        font-size: 11px;
    }
}


/*Ck Editor Css*/
.message__box .ej-ck-editor .ck-content {
    height: 80px !important;
    border-radius: 2px 2px 0 0 !important;
    word-break: break-all;
}

.conversation__text__group .text.ej-ck-editor {
    word-break: break-all;
}

.message__box .ej-ck-editor .ck.ck-toolbar {
    border-radius: 0 0 2px 2px !important;
    border-top: 0 !important;
}

#conversation-editor {
    display: flex;
    flex-direction: column-reverse;
}

</style>
